@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
$poppin:'Poppins',
sans-serif;
$black:#000;
$white:#fff;
$white-50:#ffffff80;
$gray:#888888;
$blue-1:#415094;
$blue-2:#757fac;
$mainColor:#eb6c17;
$secondaryColor:#f3745f;
$thirdColor:#F8F4ee;
$bgColor:#fff;